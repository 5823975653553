input {
    box-sizing: border-box;
    font-size: 1rem;
    padding: 1rem;
    display: block;
    margin: 2rem auto;
    border-radius: 15px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(12, 45, 65, 0.144), 0 6px 20px 0 rgba(10, 37, 59, 0.19) ;
    
    &:focus {
        outline: none;
    }
  }