.game {
  width: 600px;
  height: 200px;
  border-bottom: 1px solid $colorBlack;
  margin: auto;

  .dino {
    width: 50px;
    height: 50px;
    background-image: url(../../img/pikachu.png);
    background-size: 50px 50px;
    position: relative;
    top: 150px;
  }

  .cactus {
    width: 20px;
    height: 40px;
    background-image: url(../../img/cacti---cacti2.png);
    background-size: 20px 40px;
    position: relative;
    top: 110px;
    left: 580px;
    animation: cactusMove 1s infinite linear;
  }
}

@keyframes cactusMove {
  0% {
    left: 580px;
  }

  100% {
    left: -20px;
  }
}

.jump {
  animation: jump 0.4s linear;
}

@keyframes jump {
  0% {
    top: 150px;
  }
  30% {
    top: 130px;
  }
  50% {
    top: 80px;
  }
  80% {
    top: 130px;
  }
  100% {
    top: 150px;
  }
}
