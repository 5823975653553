.btn {
    display: flex;
    justify-content: center;
    margin: $pageSpacing 0;
    padding-bottom: $pageSpacing*2;

    button {
        border-style: none;
        padding: 10px 20px;
        margin-right: 10px;
        border-radius: 5px;
        background: $colorWhite;
        color: $colorBlack;
        font-size: 1em;
        outline: none;
        box-shadow: 0 4px 8px 0 rgba(12, 45, 65, 0.144), 0 6px 20px 0 rgba(10, 37, 59, 0.19) ;
        cursor: pointer;
        z-index: 9999; 
    }
}