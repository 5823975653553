.circle1 {
  background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), #E4B8BA;
  opacity: 0.3;
  height: 91px;
  width: 91px;
  position: absolute;
  border-radius: 50%;
  top: 10%;
  right: 15%;
}

.circle2 {
  background: white;
  background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), rgba(228, 184, 186, 0.26);
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
  bottom: 5%;
  right: 10%;
  opacity: 0.2;
}

.circle3 {
  background: white;
  background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);
  opacity: 0.2;
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
  bottom: -15%;
  right: 26%;
}

.circle4 {
  background: white;
  background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);
  height: 100px;
  width: 100px;
  position: absolute;
  border-radius: 50%;
  bottom: -30%;
  right: 5%;
}

.circle5 {
  background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), #E4B8BA;
  height: 91px;
  width: 91px;
  position: absolute;
  border-radius: 50%;
  top: 1170px;
  left: 565px;
}

.circle6 {
  background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), #E4B8BA;
  height: 32px;
  width: 32px;
  position: absolute;
  border-radius: 50%;
  top: 478px;
  left: 985px;
}

.circle7 {
  background: linear-gradient(120.62deg, #FFFFFF 21.24%, rgba(147, 139, 139, 0) 85.51%), rgba(228, 184, 186, 0.32);
  height: 266px;
  width: 266px;
  position: absolute;
  border-radius: 50%;
  top: 1100px;
  left: 20px;
}
.circle8 {
    background: linear-gradient(120.62deg, #FFFFFF 21.24%, rgba(147, 139, 139, 0) 85.51%), rgba(228, 184, 186, 0.32);
    height: 266px;
    width: 266px;
    position: absolute;
    border-radius: 50%;
    top: 1980px;
    left: 900px;
  }
  .circle9 {
    background: linear-gradient(120.62deg, #FFFFFF 21.24%, rgba(147, 139, 139, 0) 85.51%), rgba(228, 184, 186, 0.32);
    opacity: 0.8;
    height: 266px;
    width: 266px;
    position: absolute;
    border-radius: 50%;
    top: 1580px;
    left: 300px;
  }
  .circle10 {
    background: linear-gradient(120.62deg, #FFFFFF 21.24%, rgba(147, 139, 139, 0) 85.51%), rgba(228, 184, 186, 0.32);
    opacity: 0.8;
    height: 266px;
    width: 266px;
    position: absolute;
    border-radius: 50%;
    top: 3080px;
    left: 300px;
  }
  .circle11 {
    background: linear-gradient(120.62deg, #FFFFFF 21.24%, rgba(147, 139, 139, 0) 85.51%), rgba(228, 184, 186, 0.32);
    height: 266px;
    width: 266px;
    position: absolute;
    border-radius: 50%;
    top: 2580px;
    left: 700px;
  }

  .circle12 {
    background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), #E4B8BA;
    height: 32px;
    width: 32px;
    position: absolute;
    border-radius: 50%;
    top: 1678px;
    left: 1085px;
  }

  .circle13 {
    background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), #E4B8BA;
    height: 182px;
    width: 182px;
    position: absolute;
    border-radius: 50%;
    top: 1578px;
    left: 1385px;
  }

  .circle14 {
    background: linear-gradient(120.62deg, #FFFFFF 18.59%, rgba(147, 139, 139, 0) 82.86%), #E4B8BA;
    height: 82px;
    width: 82px;
    position: absolute;
    border-radius: 50%;
    top: 870px;
    left: 1385px;
  }

  @media screen and (max-width: 1250px) {
    .circle4,  .circle5, .circle6, .circle8, .circle9, .circle10, .circle11, .circle12, .circle13, .circle14 {
        display: none;
    }
  }
