@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Potta+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rammetto+One&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Comfortaa', cursive;
}

html,
body {
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%), #fbffbd;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home {
  display: flex;
  font-size: 2em;
}

.wrapper {
  -webkit-box-flex: 1;
  flex: 1 0 auto;
}

h1 {
    text-align: center;
    padding: 1em;
    font-size: 35px;
    font-family: 'Rammetto One', cursive;
}
