.Card__pokemon {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 10px 5px 20px 0px rgba(12, 45, 65, 0.144);
  border-radius: 15px;
  z-index: 9;
  overflow: hidden;
  border-top: 2px solid rgb(255, 255, 255);
  border-left: 2px solid rgba(255, 255, 255, 1);
  backdrop-filter: blur(1px);

  &--search {
    max-width: 400px;
   margin: 0 auto;
} 
.Card__id {
    position: absolute;
    top: 0px;
    right: 30px;
    font-size: 6em;
    color: rgba(170, 170, 170, 0.5);
    font-weight: bold;
    pointer-events: none;
}
  .Card__img {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: $pageSpacing*5;
    .Card__image {
      width: 200px;
    }
    
    .circle {
        width: 10rem;
        height: 10rem;
        background-image: linear-gradient(to top, #fff1eb 0%, #acf9e8 100%);
        position: absolute;
        border-radius: 50%;
        z-index: -1;
        box-shadow: 0 4px 8px 0 rgba(12, 45, 65, 0.144), 0 6px 20px 0 rgba(10, 37, 59, 0.19) ;
      }
  }

  .Card__name {
    text-align: center;
    text-transform: capitalize;
    font-weight: 800;
  }

  .Card__types {
    display: flex;
    justify-content: center;

    .Card__type {
      padding: $pageSpacing / 2 $pageSpacing;
      margin: $pageSpacing $pageSpacing $pageSpacing 0;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(12, 45, 65, 0.144), 0 6px 20px 0 rgba(10, 37, 59, 0.19) ;
    }
  }

  .Card__info {
    margin: $pageSpacing;
    z-index: 9;

    .Card__data {
      padding-bottom: $pageSpacing;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      

      .title {
        font-weight: 700;
        margin-right: $pageSpacing*2;
      }
    }
  }
}
