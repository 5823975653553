.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  z-index: 999;
  text-align: center;
  line-height: 2em;
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: $pageWidth;
    margin: 0 auto;

    .navbar-logo {
      cursor: pointer;
      padding-left: $pageSpacing;

      .navbar-img {
        width: 50px;
      }
    }

    .nav-menu {
      display: grid;
      grid-template-columns: repeat(3, auto);
      list-style: none;
      text-align: center;
      width: 60vw;
      justify-content: start;

      .nav-items {
        height: 80px;

        .nav-links {
          color: $colorBlack;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          padding: 0.5rem 2rem;
          height: 100%;
          font-family: 'Rammetto One', cursive;
          &:hover {
            border-bottom: 4px solid #f3f04e;
            transition: all 0.2s ease-out;
            text-decoration: none;
          }
        }
      }
    }
  }

  .menu-icon {
    display: none;
  }
}

@media screen and (max-width: 1250px) {
  .navbar {
    position: relative;
    height: 80px;

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      padding: $pageSpacing*1.5;
      font-size: 1.8rem;
      cursor: pointer;

      .fa-times {
        color: $colorBlack;
        font-size: 2rem;
      }

      .fa-bars {
        color: $colorBlack;
      }
    }

    .navbar-container {
      height: 300px;
      padding: 80px 0;
      width: 100%;

      .navbar-logo {
        position: absolute;
        top: 0;
        right: 0;
        padding: $pageSpacing;
      }

      .nav-menu {
        display: none;

        .nav-items {
          list-style: none;
          margin: $pageSpacing;

        }

        &--active {
          height: 100%;
          padding-top: 1.5em;
          background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
          width: 100%;
          right: 0;
          transition: all 0.5s ease;

          .nav-links {
            padding: $pageSpacing;
            margin: $pageSpacing;
            width: 100%;
            color: $colorWhite;
            list-style: none;
            text-decoration: none;
            font-family: 'Rammetto One', cursive;

            &:hover {
              color: red;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .navbar-logo {
    padding: $pageSpacing $pageSpacing;
  }

  .fa-bars {
    padding: 0;
  }
}
