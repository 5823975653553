.card-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    .pokemon {
      min-height: 55vh;
      display: flex;
      align-items: center;
      justify-content: center;

      .cards-form img {
        width: 25rem;
        z-index: 9999;
        transition: all 0.75s ease-out;
      }

      .circle {
        width: 15rem;
        height: 15rem;
        background: linear-gradient(to right,
            rgba(255, 34, 31, 0.75),
            rgba(60, 78, 65, 0.75));
        position: absolute;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
}
